import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Container, Row, Col } from 'react-bootstrap';
import ServicesSection from './ServicesSection';
import { Link } from "react-router-dom";
import Meta from './shared/Meta';
import '../images/banner.jpg'


function Home() {
    return (
        <>
            <Meta title="Welcome to the world of RBK Overseas" keyword="RBK Overseas" description="RBK Overseas is a global supplier, distributor and trader of recyclable, non-prime, and Prime raw materials Minerals. We are headquartered in London and we deal in an array of products including, Plastic, Paper, Steel, Minerals and Timber." />

            <section className="section-divider Banner-sec Background" style={{ backgroundImage: `url("../images/banner.jpg")` }}>
                <div className="Banner-section">
                    <Container>
                        <Row className="align-item-center">
                            <Col md="9">
                                <div className="Banner-inner">
                                    <div className="Banner-title text-center">
                                        <h1 className="banner-heading text-white">Welcome to the world of <br/>RBK Overseas UK Limited</h1>
                                        <p className="text-white">RBK Overseas UK Limited is a global supplier, distributor and trader of recyclable, non-prime, and Prime raw materials Minerals. We are headquartered in London and we deal in an array of products including, Plastic, Paper, Steel, Minerals and Timber.</p>
                                    </div>
                                    <div className="home-cta flex-btn justify-content-center text-center mt-5">
                                        <Link to="/contact" title="Get In Touch" className="btn-main home-cta-1">Get In Touch</Link> </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="align-items-center flex-row-reverse">
                        <Col lg="6" md="12">
                            <div className="services-image">
                                <img src="images/about.jpg" className="img-fluid" alt="image" />
                            </div>
                        </Col>
                        <Col lg="6" md="12">
                            <div className="services-block">
                                <h2 className="heading">About <span>Us</span></h2>
                                <p>RBK Overseas was founded in UK, with a mission trade to get the best outcome for everyone. We specialize in the global buying and selling of recyclable and non-prime raw materials. We strongly believe in offering our customers a consistent and dependable supply of quality raw material with competitive pricing.</p>
                                <h3 className="py-3"><strong>CORE VALUES</strong></h3>
                                <ul className="check-list">
                                    <li>Sustainability</li>
                                    <li>Quality</li>
                                    <li>Commitment</li>
                                    <li>Integrity</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-divider services-sec gray-bg mb-0">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="8" md="12">
                            <div className="text-center mb-5">
                                <h2 className="heading text-center ">OUR BUSINESS <span>UNITS </span></h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xl="4" lg="4" md="6">
                            <ServicesSection
                                serviceheading="METALS"
                                servicedsclink="/metals"
                                serviceicon="images/metals.jpg"
                                servicedsc="We deliver the material you need at your doorstep with competitive conditions. We add value in the supply chain with our efficient logistic services, innovative trade finance tools, global sourcing of materials, and the market knowledge."></ServicesSection>
                        </Col>
                        <Col xl="4" lg="4" md="6">
                            <ServicesSection
                                serviceheading="Paper"
                                servicedsclink="/paper"
                                serviceicon="images/paper.jpg"
                                servicedsc="We deal in Prime, Stocklot and Waste Grade products from some of the world’s leading Paper producers and renowned yards."></ServicesSection>
                        </Col>

                        <Col xl="4" lg="4" md="6">
                            <ServicesSection
                                serviceheading="Plastic"
                                servicedsclink="/plastic"
                                serviceicon="images/plastic.jpg"
                                servicedsc="RBK provides solutions for all types of Plastics scrap and reprocessed materials. We have a ready market to give a second life to your plastic waste."></ServicesSection>
                        </Col>

                        <Col xl="4" lg="4" md="6">
                            <ServicesSection
                                serviceheading="Minerals"
                                servicedsclink="/minerals"
                                serviceicon="images/minerals.jpg"
                                servicedsc="We source the materials directly from the reputed mines and ship to our customers to give a consistent quality."></ServicesSection>
                        </Col>
                        <Col xl="4" lg="4" md="6">
                            <ServicesSection
                                serviceheading="Timber"
                                servicedsclink="/timber"
                                serviceicon="images/timber.jpg"
                                servicedsc="Pine, Eucalyptus, Teak, Tali, Bilinga, Azobe, Limbali, Okan, Padouk, Basralocus, WPL, KAW, Purpleheart, Wana, ZWK, Greenheart are some type of woods we deal with."></ServicesSection>
                        </Col>

                    </Row>

                </Container>
            </section>

            <section className="section-divider gray-bg  get-started-now-bg mb-0" >
                <div className="get-started-now-sec">
                    <Container>
                        <div className="get-started-now-inner d-flex justify-content-center align-items-center">
                            <h2 className="heading">Want to <i>contact us?</i></h2>
                             <a href="tel:+4402038078779" className="btn-main white-btn"><i className="fa-solid fa-phone"></i> Call Now</a>
                        </div>
                    </Container>
                </div>
            </section>

        </>
    );
}
export default Home;