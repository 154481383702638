import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Container, Row, Col } from 'react-bootstrap';
import Meta from './shared/Meta';
import Otherservices from './shared/Otherservices';
import { Link } from "react-router-dom";
import '../images/timber-banner.jpg'

function Timber() {
    return (

        <>
            <Meta title="Paper RBK Overseas UK Limited" />

            <section className="section-divider inner-banner Background" style={{ backgroundImage: `url("../images/timber-banner.jpg")` }}>
                <div className="inner-banner-page">
                    <Container>
                        <Row className="align-item-center justify-content-center">
                            <Col lg="8" md="12">
                                <div className="inner-banner-dsc text-center">
                                    <h1 className="banner-heading text-center">Timber <span>Unit</span></h1>
                                    <p>Wooden, additionally known as Lumber is the uncooked wood fabric this is customized and machine cut into dimensional boards according to their width, thickness, and duration. wood is predominantly used for the reason of structural creation, and numerous other needs.</p>
                                    <div className="home-cta flex-btn justify-content-center">
                                        <a className="btn-main home-cta-1">Logs</a><a className="btn-main home-cta-1">Easy square of constant Sizes</a><a className="btn-main home-cta-1">Rough Squares</a></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="8" md="12">
                            <div className="text-center mb-5">
                                <h2 className="heading text-center ">About <span>Timber Unit</span></h2>
                                <p>RBK Overseas UK Limited provides high fine and a wide variety of amazing and imported wooden for residential and business purposes consistent with application. The timber presented with the aid of us is of premium grade and subtle and offers a categorised touch to the house and workplace décor.</p>
                                <p>Timber comes in the shape of logs, sleeper, reduce size and also inside the customized sizes given by means of the customers consistent with their software of usage. diverse kind timbers are imported from the exceptional international locations.</p>
                                <p>For <b>Timber</b> Trading please get in touch with our inhouse specialist at</p>
                                <p><strong>Mobile: <Link to="tel:+4402038078779"><u>+4402038078779</u></Link> | Email: <Link to="mailto:info@rbkoverseas.co.uk"><u>info@rbkoverseas.co.uk</u></Link></strong></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="align-items-center flex-row-reverse">
                        <Col lg="6" md="6">
                            <div className="services-image">
                                <img src="images/timber.jpg" className="img-fluid w-100" alt="image" />
                            </div>
                        </Col>
                        <Col lg="6" md="6">
                            <div className="services-block">
                                <h2 className="heading">Types Of wood:</h2>
                                <Row className="align-items-center flex-row-reverse">
                                    <Col lg="6" md="6">
                                        <ul className="check-list">
                                            <li>Pine</li>
                                            <li>Eucalyptus</li>
                                            <li>Teak</li>
                                            <li>Tali</li>
                                            <li>Bilinga</li>
                                            <li>Azobe</li>
                                            <li>Limbali</li>
                                            <li>Okan</li>

                                        </ul>
                                    </Col>
                                    <Col lg="6" md="6">
                                        <ul className="check-list">
                                            <li>Padouk</li>
                                            <li>Basralocus</li>
                                            <li>WPL</li>
                                            <li>KAW</li>
                                            <li>Purpleheart</li>
                                            <li>Wana</li>
                                            <li>ZWK</li>
                                            <li>Greenheart</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="align-items-center">
                        <Col lg="6" md="6">
                            <div className="services-image">
                                <img src="images/timber-1.jpg" className="img-fluid w-100" alt="image" />
                            </div>
                        </Col>
                        <Col lg="6" md="6">
                            <div className="services-block">
                                <h2 className="heading">Types of Shipment:</h2>
                                <ul className="check-list">
                                    <li>Logs</li>
                                    <li>Clean Square of Fixed Sizes</li>
                                    <li>Rough Squares</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Otherservices />

        </>
    );
}
export default Timber;