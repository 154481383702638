
import './App.css'; 
import Headerbar from './components/Headerbar'; 
import Aboutus from './components/Aboutus';
import Home from './components/Home';
import Services from './components/Services'; 
import Contact from './components/Contact'; 
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from '../src/components/Footer';
import Carrer from '../src/components/Carrer';
import Metals from '../src/components/Metals';
import Paper from '../src/components/Paper';
import Plastic from './components/Plastic';
import Minerals from '../src/components/Minerals';
import Timber from '../src/components/Timber';
import Pagenotfound from '../src/components/shared/Pagenotfound';
import ScroolPage from './components/shared/ScroolPage';


function App() {
  return (

    <>
    <BrowserRouter>
    <ScroolPage/>
    <div className="App"> 
      <div className="Header-sec"> 
        <Headerbar /> 
      </div> 
<Routes onUpdate={() => window.scrollTo(0, 0)} >
  <Route path='/' element={<Home/>} />
  <Route path='/aboutus' element={<Aboutus/>} />  
  <Route path='/services' element={<Services/>} /> 
  <Route path='/contact' element={<Contact/>} />  
  <Route path='/carrer' element={<Carrer/>} />  
  <Route path='/metals' element={<Metals/>} />  
  <Route path='/paper' element={<Paper/>} />  
  <Route path='/plastic' element={<Plastic/>} />  
  <Route path='/minerals' element={<Minerals/>} />  
  <Route path='/timber' element={<Timber/>} />  
  <Route path='*' element={<Pagenotfound/>} />  
</Routes>

     <Footer/>
    </div>

    </BrowserRouter>


 



    </>
  );
}

export default App;
