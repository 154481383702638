import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ServicesSection from './ServicesSection';
import Meta from './shared/Meta'; 


function Services() {
    return (
        <>
            <Meta title="Strategic Business Units" />

            <section className="section-divider inner-banner Background mb-0" style={{ backgroundImage: `url("../images/home-banner2.jpg")` }}>
                <div className="inner-banner-page">
                    <Container>
                        <Row className="align-item-center justify-content-center">
                            <Col lg="8" md="12">
                                <div className="inner-banner-dsc text-center">
                                    <h1 className="banner-heading text-center">
Business   <span>units</span></h1>
                                    <div className="home-cta flex-btn justify-content-center" >
                                        <Link className="btn-main home-cta-1" title="METAL" to="/metals">METAL</Link>
                                        <Link className="btn-main home-cta-1" title="PAPER" to="/paper">PAPER</Link>
                                        <Link className="btn-main home-cta-1" title="PLASTICS" to="/plastic">PLASTICS</Link>
                                        <Link className="btn-main home-cta-1" title="MINERALS" to="/minerals">MINERALS</Link>
                                        <Link className="btn-main home-cta-1" title="TIMBER" to="/timber">TIMBER</Link>
                                        </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>

            <section className="section-divider services-sec gray-bg mb-0">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="8" md="12">
                            <div className="text-center mb-5">
                                <h2 className="heading text-center ">OUR BUSINESS <span>UNITS </span></h2>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col xl="4" lg="4" md="6">
                            <ServicesSection
                                serviceheading="METALS"
                                servicedsclink="/metals"
                                serviceicon="images/metals.jpg"
                                servicedsc="We deliver the material you need at your doorstep with competitive conditions. We add value in the supply chain with our efficient logistic services, innovative trade finance tools, global sourcing of materials, and the market knowledge."></ServicesSection>
                        </Col>

                        <Col xl="4" lg="4" md="6">
                            <ServicesSection
                                serviceheading="Paper"
                                servicedsclink="/paper"
                                serviceicon="images/paper.jpg"
                                servicedsc="We deal in Prime, Stocklot and Waste Grade products from some of the world’s leading Paper producers and renowned yards."></ServicesSection>
                        </Col>

                        <Col xl="4" lg="4" md="6">
                            <ServicesSection
                                serviceheading="Plastic"
                                servicedsclink="/plastic"
                                serviceicon="images/plastic.jpg"
                                servicedsc="RBK provides solutions for all types of Plastics scrap and reprocessed materials. We have a ready market to give a second life to your plastic waste."></ServicesSection>
                        </Col>

                        <Col xl="4" lg="4" md="6">
                            <ServicesSection
                                serviceheading="Minerals"
                                servicedsclink="/minerals"
                                serviceicon="images/minerals.jpg"
                                servicedsc="We source the materials directly from the reputed mines and ship to our customers to give a consistent quality."></ServicesSection>
                        </Col>
                        <Col xl="4" lg="4" md="6">
                            <ServicesSection
                                serviceheading="Timber"
                                servicedsclink="/Timber"
                                serviceicon="images/timber.jpg"
                                servicedsc="Pine, Eucalyptus, Teak, Tali, Bilinga, Azobe, Limbali, Okan, Padouk, Basralocus, WPL, KAW, Purpleheart, Wana, ZWK, Greenheart are some type of woods we deal with."></ServicesSection>
                        </Col>

                    </Row>

                </Container>
            </section>

        </>
    );
}
export default Services;