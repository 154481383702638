import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Container, Row, Col } from 'react-bootstrap';
import Meta from './shared/Meta';
import Otherservices from './shared/Otherservices';
import { Link } from "react-router-dom";
import '../images/paper-banner.jpg'

function Paper() {
    return (

        <>
            <Meta title="Paper RBK Overseas UK Limited" />

            <section className="section-divider inner-banner Background" style={{ backgroundImage: `url("../images/paper-banner.jpg")` }}>
                <div className="inner-banner-page">
                    <Container>
                        <Row className="align-item-center justify-content-center">
                            <Col lg="8" md="12">
                                <div className="inner-banner-dsc text-center">
                                    <h1 className="banner-heading text-center">Paper <span>Unit</span></h1>
                                    <p>RBK Overseas UK Limited provides a linkage between supply and demand in the waste paper business.</p>
                                    <div className="home-cta flex-btn justify-content-center">
                                        <a className="btn-main home-cta-1"  >Prime Products</a><a className="btn-main home-cta-1"  >Stocklotp</a><a className="btn-main home-cta-1" >Recyclable/Waste Grades</a></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="8" md="12">
                            <div className="text-center mb-5">
                                <h2 className="heading text-center ">About <span>About Unit</span></h2>
                                <p>We deal in Prime, Stocklot and Waste Grade products from some of the world’s leading Paper producers and renowned yards.</p>

                                <p>For <b>Paper Trading</b> Trading please get in touch with our inhouse specialist at</p>
                                <p><strong>Mobile: <Link to="tel:+4402038078779"><u>+4402038078779</u></Link> | Email: <Link to="mailto:info@rbkoverseas.co.uk"><u>info@rbkoverseas.co.uk</u></Link></strong></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="align-items-center flex-row-reverse">
                        <Col lg="6" md="6">
                            <div className="services-image">
                                <img src="images/paper2.jpg" className="img-fluid w-100" alt="image" />
                            </div>
                        </Col>
                        <Col lg="6" md="6">
                            <div className="services-block">
                                <h2 className="heading">Prime <span>Products</span></h2>
                                <ul className="check-list">
                                    <li>Kraft paper</li>
                                    <li>Folding Box Boards (FBB)</li>
                                    <li>Testliner and Fluting</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="align-items-center">
                        <Col lg="6" md="6">
                            <div className="services-image">
                                <img src="images/paper-scrap.jpg" className="img-fluid w-100" alt="image" />
                            </div>
                        </Col>
                        <Col lg="6" md="6">
                            <div className="services-block">
                                <h2 className="heading">Stocklot</h2>
                                <ul className="check-list">
                                    <li>Kraftliner Brown (Virgin Fiber)</li>
                                    <li>White Top Kraft Liner / Coated White Top Kraft Liner</li>
                                    <li>Testliner / Testliner 2 / Testliner 3 / Testliner 4</li>
                                    <li>Corrugating medium / fluting/ High Performance Fluting/Schrenz gray / brown</li>
                                    <li>Silicone Rolls</li>
                                    <li>Bleach and Unbleached Kraft</li>
                                    <li>Newsprint</li>
                                    <li>Uncoated Woodfree Reels</li>
                                    <li>Duplex Board and Sheets</li>
                                    <li>Other Specialised grades</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="align-items-center flex-row-reverse">
                        <Col lg="6" md="6">
                            <div className="services-image">
                                <img src="images/paper3.jpg" className="img-fluid w-100" alt="image" />
                            </div>
                        </Col>
                        <Col lg="6" md="6">
                            <div className="services-block">
                                <h2 className="heading">Recyclable Paper / Waste Grades</h2>
                                <ul className="check-list">
                                    <li>OCC</li>
                                    <li>Tissue Waste</li>
                                    <li>Core Pipes</li>
                                    <li>SOP</li>
                                    <li>SWL</li>
                                    <li>Scan Boards</li>
                                    <li>DLK &amp; NDLKC</li>
                                    <li>Kraft Multiwall Bags</li>
                                    <li>SMK</li>
                                    <li>HWS &amp; SWS</li>
                                    <li>Other Specialised grades</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Otherservices />

        </>
    );
}
export default Paper;