import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Container, Row, Col } from 'react-bootstrap';
import Meta from './shared/Meta';
import Otherservices from './shared/Otherservices';
import { Link } from "react-router-dom";
import '../images/home-banner3.jpg'

function Minerals() {
    return (

        <>
            <Meta title="Paper RBK Overseas UK Limited" />

            <section className="section-divider inner-banner Background" style={{ backgroundImage: `url("../images/home-banner3.jpg")` }}>
                <div className="inner-banner-page">
                    <Container>
                        <Row className="align-item-center justify-content-center">
                            <Col lg="8" md="12">
                                <div className="inner-banner-dsc text-center">
                                    <h1 className="banner-heading text-center">Minerals <span>Unit</span></h1>
                                    <p>RBK Overseas UK Limited Community give results for all types of Minerals and Ores.</p>
                                    <div className="home-cta flex-btn justify-content-center"><a className="btn-main home-cta-1" >Minerals and Ores</a></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="8" md="12">
                            <div className="text-center mb-5">
                                <h2 className="heading text-center ">About <span>Minerals  Unit</span></h2>
                                <p>Our associate offices worldwide work as your partner to provide you with a high and consistent quality of Copper Ore, Manganese Ore, Iron Ore, Chrome Ore or any other mineral you require.Our associate offices worldwide work as your partner to provide you with a high and consistent quality of Copper Ore, Manganese Ore, Iron Ore, Chrome Ore or any other mineral you require.</p>

                                <p>For <b>Minerals Trading</b> Trading please get in touch with our inhouse specialist at</p>
                                <p><strong>Mobile: <Link to="tel:+4402038078779"><u>+4402038078779</u></Link> | Email: <Link to="mailto:info@rbkoverseas.co.uk"><u>info@rbkoverseas.co.uk</u></Link></strong></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="align-items-center flex-row-reverse">
                        <Col lg="6" md="6">
                            <div className="services-image">
                                <img src="images/minerals.jpg" className="img-fluid w-100" alt="image" />
                            </div>
                        </Col>
                        <Col lg="6" md="6">
                            <div className="services-block">
                                <h2 className="heading">Minerals <span>and Ores</span></h2>
                                <p>We supply high quality minerals and ores for applications in various industrial segments to our customers worldwide.</p>
                                <p>Certification by any reputed independent surveyor at the time of loading of the cargo.</p>
                                <p>Specifications can be provided on request.</p>
                                <p>We deal in Iron ore, Manganese Ore, Chrome Ore, Copper Ore &amp; Concentrates.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Otherservices />
        </>
    );
}
export default Minerals;