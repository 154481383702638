

import { Row, Col, Container } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Meta from './shared/Meta'; 
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../images/banner.jpg'
import { Link } from "react-router-dom";
import Globalpresence from "./Globalpresence";


function Contact() {


  


    const { register, formState: { errors }, handleSubmit } = useForm();

    function contactform(data) {
        console.log(data)
    }

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_67vbd5r', 'template_anmy9jj', form.current, 'hJfE2ougHSy0vpZER')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };


   




  
    return (


        

        <>
            <Meta title="Contact Us" />

            <section className="section-divider inner-banner Background" style={{ backgroundImage: `url("../images/banner.jpg")` }}>
                <div className="inner-banner-page">
                    <Container>
                        <Row className="align-item-center justify-content-center">
                            <Col lg="8" md="12">
                                <div className="inner-banner-dsc text-center">
                                    <h1 className="banner-heading text-center">Contact <span>Us</span></h1>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>

       

            <div className="section-divider">
                <div className="inner-banner-form-block">
                    <Container>

                    {/* <form ref={form} onSubmit={sendEmail}>
                <label>Name</label>
                <input type="text" name="user_name" />
                <label>Email</label>
                <input type="email" name="user_email" />
                <label>Message</label>
                <textarea name="message" />
                <input type="submit" value="Send" />
            </form> */}
            
                        <Row>
                            <Col md="6">
                            <div className="inner-banner-form mt-0">
                            <div className="services-image ">
                                <img src="images/contact.png" className="img-fluid" alt="image" />
                            </div>
                            </div>
                                <div className="inner-banner-form mt-0 d-none">
                                    <h2 className="heading">Contact Us by submitting <br className="d-block d-md-none" />the form below:</h2>
                                    {/* <form method="post" ref={form} onSubmit={handleSubmit(contactform)}> */}
                                    <form ref={form} onSubmit={sendEmail}>
                                        <Row className=" ">

                                            <Col md="12">
                                                <div className="form-group">
                                                    <input className="form-control" id="name" type="text" {...register("name", { required: "Name is required" })} placeholder="Your Name*" />
                                                    <span class="error-msg">{errors.name?.message}</span>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="form-group">
                                                    <input className={`form-control ${errors.phone ? "invalid" : ""}`} id="phone" type="text" {...register("phone", { required: "Please Enter Phone No." })} placeholder="Phone*" />
                                                    <span class="error-msg">{errors.phone?.message}</span>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="form-group">
                                                    <input className={`form-control ${errors.email ? "invalid" : ""}`} id="Emai" type="email" {...register("email", { required: "Please Enter Email" })} placeholder="Email*" />
                                                    <span class="error-msg">{errors.email?.message}</span>
                                                </div>
                                            </Col>

                                            <Col md="12">
                                                <div className="form-group">
                                                    <textarea className={`form-control ${errors.message ? "invalid" : ""}`} Rows="5" id="Emai" type="email" {...register("message", { required: "Please Select Message" })} placeholder="Message*" />
                                                    <span class="error-msg">{errors.message?.message}</span>
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="form-group text-center mb-0">
                                                <input type="submit" className="btn-main" value="Send" />
                                                
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="contact-address-single">
                                    <div className="contact-icon-desc-area">
                                        <div className="contact-icon"> <i className="fas fa-phone"></i> </div>
                                        <div className="contact-desc">
                                            <p><strong>Telephone Number:</strong> </p>
                                            <p><Link to="tel:+4402038078779" title="Phone">+44 (0) 20 3807 8779</Link></p>
                                        </div>
                                    </div>

                                    <div className="contact-icon-desc-area">
                                        <div className="contact-icon"> <i className="fas fa-phone"></i> </div>
                                        <div className="contact-desc">
                                            <p><strong>Mobile Number:</strong> </p>
                                            <p><Link to="tel:+447823397191" title="Phone">+44 7823397191</Link> <Link to="tel:+447448350715" title="Phone"> +44 7448350715 </Link></p>
                                        </div>
                                    </div> 
                                    <div className="contact-icon-desc-area  ">
                                        <div className="contact-icon"> <i className="fas fa-envelope"></i> </div>
                                        <div className="contact-desc">
                                            <p><strong>Email:</strong> </p>
                                            <p><Link to="mailto:info@rbkoverseas.co.uk" title="Email">info@rbkoverseas.co.uk</Link></p>
                                        </div>
                                    </div>
                                    <div className="contact-icon-desc-area">
                                        <div className="contact-icon">
                                            <i className="fas fa-location"></i>
                                        </div>
                                        <div className="contact-desc">
                                            <p><strong>Address:</strong></p>
                                            <p><Link to="#!" title="Get Direction" target="_blank">
                                                <span><strong>RBK Overseas UK Limited</strong><br />
167-169 Great Portland Street, London  England W1W 5PF</span>.</Link></p>
                                            
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="section-divider">
                <div className="inner-banner-form-block">
                   <Globalpresence/> 
                </div>
            </div>

        </>


    );
}
export default Contact;


