import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Container, Row, Col } from 'react-bootstrap';
import Meta from './shared/Meta';
import Otherservices from './shared/Otherservices';
import { Link } from "react-router-dom";
import '../images/plastic-banner.jpg'

function Plastic() {
    return (

        <>
            <Meta title="Plastics RBK Overseas UK Limited" />

            <section className="section-divider inner-banner Background" style={{ backgroundImage: `url("../images/plastic-banner.jpg")` }}>
                <div className="inner-banner-page">
                    <Container>
                        <Row className="align-item-center justify-content-center">
                            <Col lg="8" md="12">
                                <div className="inner-banner-dsc text-center">
                                    <h1 className="banner-heading text-center">Plastics <span>Unit</span></h1>
                                    <p>RBK Overseas UK Limited provides solutions for all types of Plastics Scrap and Reprocessed Materials. We have a ready market to give a second life to plastic waste.</p>
                                    <div className="home-cta flex-btn justify-content-center">
                                        <a className="btn-main home-cta-1">Plastic Film Scrap</a>
                                        <a className="btn-main home-cta-1">PET, Engineering and Hard Plastics</a>
                                        <a className="btn-main home-cta-1">Reprocessed Granules and Resins</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="8" md="12">
                            <div className="text-center mb-5">
                                <h2 className="heading text-center ">About <span>Plastics Unit</span></h2>
                                <p>We deal in all types of Film, Bottles, Engineering, and Hard Plastics scraps. We provide a specific solution to plastic waste in order to recycle it in a sustainable way.</p>

                                <p>For <b>Plastic Trading</b> Trading please get in touch with our inhouse specialist at</p>
                                <p><strong>Mobile: <Link to="tel:+4402038078779"><u>+4402038078779</u></Link> | Email: <Link to="mailto:info@rbkoverseas.co.uk"><u>info@rbkoverseas.co.uk</u></Link></strong></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="align-items-center flex-row-reverse">
                        <Col lg="6" md="6">
                            <div className="services-image">
                                <img src="images/plasticfilmscrap.jpg" className="img-fluid w-100" alt="image" />
                            </div>
                        </Col>
                        <Col lg="6" md="6">
                            <div className="services-block">
                                <h2 className="heading">Plastic Film  <span>Scrap</span></h2>
                                <ul className="check-list">
                                    <li>LDPE Film Scrap 98/02</li>
                                    <li>LDPE Film Scrap 90/10</li>
                                    <li>LDPE Film Scrap 80/20</li>
                                    <li>LDPE Film Scrap 60/40</li>
                                    <li>PP Big Bags</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="align-items-center">
                        <Col lg="6" md="6">
                            <div className="services-image">
                                <img src="images/plastic.jpg" className="img-fluid w-100" alt="image" />
                            </div>
                        </Col>
                        <Col lg="6" md="6">
                            <div className="services-block">
                                <h2 className="heading">PET, Engineering and Hard Plastics <span>Scrap</span></h2>
                                <ul className="check-list">
                                    <li>ABS, PMMA, PC, SAN, POM, PA (6,66,11,12) PA+GF (10-40%) PC/ABS, PC/PBT</li>
                                    <li>HDPE, PP, ABS, PC, PS regtinds</li>
                                    <li>PET Bottles and Flakes</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="align-items-center flex-row-reverse">
                        <Col lg="6" md="6">
                            <div className="services-image">
                                <img src="images/granules-and-resins.jpg" className="img-fluid w-100" alt="image" />
                            </div>
                        </Col>
                        <Col lg="6" md="6">
                            <div className="services-block">
                                <h2 className="heading">Reprocessed Granules and Resins<span>Scrap</span></h2>
                                <ul className="check-list">
                                    <li>LDPE, HDPE, PP reprocessed granules</li>
                                    <li>LDPE, HDPE, PP Off Spec materials</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Otherservices />

        </>
    );
}
export default Plastic;