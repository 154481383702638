import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom";

function Footer() {

    const getCurrentYear = () => {
        return new Date().getFullYear();
      };


    return (
        <>
            <div className="footer-bg">
                <Container>
                    <Row>
                        <Col xl="4" lg="4">
                            <div className="footer-left-block">
                                <div className="footer-logo ">
                                    <Link to="/" title="RBK Overseas UK Limited">
                                        <img src="images/logo-white.png" class="img-fluid" alt="RBK Overseas UK Limited" />
                                    </Link>
                                    <p className="mt-3 text-white">RBK Overseas UK Limited is an internationally diversified company that sources products from more than 15 countries and markets them in more than 25 countries. RBK Overseas UK Limited is a global supplier, distributor and trader of recyclable, non-prime, and Prime raw materials Minerals. We are headquartered in London and we deal in an array of products including, Plastic, Paper, Steel, Minerals and Timber.</p>
                                </div>
                            </div>
                        </Col>
                        <Col xl="4" lg="4" md="6">
                            <div className="footer-left-block">
                                <h2 className="foot-head text-white position-relative">Useful <span>Links</span></h2>
                                <Row>
                                    <Col md="12">
                                        <ul className="footer-links useful-links">
                                        <li><Link to="/" title="Home"><i class="fas fa-angle-right"></i> Home</Link></li>
                                        <li><Link to="/aboutus" title="About us"><i class="fas fa-angle-right"></i> About us</Link></li>
                                        <li><Link to="/services" title="Business units"><i class="fas fa-angle-right"></i> Business units</Link></li>
                                        <li><Link to="/contact" title="Contact Us"><i class="fas fa-angle-right"></i> Contact Us</Link></li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xl="4" lg="4" md="6">
                            <h2 className="foot-head text-white position-relative">Contact <span>Us</span> </h2>
                            <ul className="footer-links address-details">
                                <li><Link to="tel:+4402038078779" title="Phone"><i className="fas fa-phone"></i> &nbsp;&nbsp; <span>+44 (0) 20 3807 8779</span></Link></li>
                                  <li> 
                                            <p><i className="fas fa-phone"></i> &nbsp;&nbsp; <Link to="tel:+447823397191" title="Phone">+44 7823397191</Link> <Link to="tel:+447448350715" title="Phone"> +44 7448350715 </Link></p>
                                            </li>
                                            <li> 
                                            <p><i className="fas fa-envelope"></i> &nbsp;&nbsp; <Link to="tel:info@rbkoverseas.co.uk" title="Email"> info@rbkoverseas.co.uk</Link>   </p>
                                            </li>

                                <li><p><i className="fas fa-location"></i>  &nbsp;&nbsp; <span><strong>RBK Overseas UK Limited</strong><br />
167-169 Great Portland Street, London  England W1W 5PF</span></p>
                                </li>

                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="copyright-bg">
                <div className="container">
                    <div className="copyright text-center">
                        <p className="m-0">Copyright <i className="fal fa-copyright"></i> {getCurrentYear()} RBK Overseas UK Limited | All Rights
                            Reserved</p>
                    </div>
                </div>
            </div>
        </>


    );

}
export default Footer;