import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Container, Row, Col } from 'react-bootstrap';
import Meta from './shared/Meta';
import Otherservices from './shared/Otherservices'; 
import { Link } from "react-router-dom";
import '../images/home-banner2.jpg' 


function Metals() {
    return (

        <>
            <Meta title="Metals RBK Overseas UK Limited" />
 
            <section className="section-divider inner-banner Background" style={{ backgroundImage: `url("../images/home-banner2.jpg")` }}>
                <div className="inner-banner-page">
                    <Container>
                        <Row className="align-item-center justify-content-center">
                            <Col lg="8" md="12">
                                <div className="inner-banner-dsc text-center">
                                    <h1 className="banner-heading text-center">Metal <span>Unit</span></h1>
                                    <p>RBK Overseas UK Limited Community give results for all types of Ferrous andNon-Ferrous Metal Scraps andNon-Prime Accoutrements.</p>
                                    <div className="home-cta flex-btn justify-content-center">
                                        <a className="btn-main home-cta-1"  >Ferrous Scrap</a><a className="btn-main home-cta-1"  >Non-Ferrous Scrap</a><a className="btn-main home-cta-1" >Non-Prime Steel</a></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="8" md="12">
                            <div className="text-center mb-5">
                                <h2 className="heading text-center ">About <span>Metal Unit</span></h2>
                                <p>We deliver the material you need at your doorstep with competitive pricing. We add value in the force chain with our effective logistic services, innovative trade finance tools, global sourcing of accoutrements , and our wealth of request knowledge.</p>

                                <p>For <b>Ferrous / Non Ferrous  | Stainless Steel | Non Prime</b> Trading please get in touch with our inhouse specialist at</p>
                                <p><strong>Mobile: <Link to="tel:+4402038078779"><u>+4402038078779</u></Link> | Email: <Link to="mailto:info@rbkoverseas.co.uk"><u>info@rbkoverseas.co.uk</u></Link></strong></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="align-items-center flex-row-reverse">
                        <Col lg="6" md="6">
                            <div className="services-image">
                                <img src="images/metal1.jpg" className="img-fluid w-100" alt="image" />
                            </div>
                        </Col>
                        <Col lg="6" md="6">
                            <div className="services-block">
                                <h2 className="heading">Ferrous  <span>Scrap</span></h2>
                                <ul className="check-list">
                                    <li>Shredded Steel Grade</li>
                                    <li>Plate and Structural (PNS)</li>
                                    <li>Steel Turnings</li>
                                    <li>Blue Steel</li>
                                    <li>Busheling Scrap (Loose or bundles)</li>
                                    <li>Rail Scrap</li>
                                    <li>Tyre wire Scrap</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="align-items-center">
                        <Col lg="6" md="6">
                            <div className="services-image">
                                <img src="images/metal2.jpg" className="img-fluid w-100" alt="image" />
                            </div>
                        </Col>
                        <Col lg="6" md="6">
                            <div className="services-block">
                                <h2 className="heading">Non-Ferrous <span>Scrap</span></h2>
                                <ul className="check-list">
                                    <li>Electric Motor Scrap</li>
                                    <li>Compressors</li>
                                    <li>Household Cables</li>
                                    <li>Shredded and Armatures</li>
                                    <li>Stainless Steel Scrap</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-divider">
                <Container>
                    <Row className="align-items-center flex-row-reverse">
                        <Col lg="6" md="6">
                            <div className="services-image">
                                <img src="images/metal4.jpg" className="img-fluid w-100" alt="image" />
                            </div>
                        </Col>
                        <Col lg="6" md="6">
                            <div className="services-block">
                                <h2 className="heading">Non-Prime   <span>Steel Scrap</span></h2>
                                <ul className="check-list">
                                    <li><strong>Flat-rolled products:</strong><br />
                                        Secondary, Over Rolling, Prime Excess, Rejected, Defective, Coils and Baby Coils &#8211; HR, CR, Galvanised, Electro galvanized, Preprinted, Alu zinc, Aluminised, Tin, and black plated coils.</li>
                                    <li><strong>Sheet and Plates</strong>:<br />
                                        HR, CR, Galvanised, Electro galvanized, pre-printed, assorted and un-assorted Sheets and plates</li>
                                    <li><strong>Long Products:</strong><br />
                                        Non-prime and secondary Wire rods, Bars and Shafts, Pipes</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

 <Otherservices/>
     
        </>
    );
}
export default Metals;